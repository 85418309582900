import { getInformationWithData } from "../services/CABE"
import { getValueCookie } from "../services/cookieService"
import { getIndexElement } from "./searchInObject"

export function intervalTimeInDays(datestart, datefinish) {
    let day1 = new Date(datestart)
    let day2 = new Date(datefinish)

    let difference = day2 - day1
    let days = difference / (1000 * 3600 * 24)
    days = days.toFixed(0)
    if (days >= 0) {
        if (String(days).length === 1) {
            return "0" + days
        } else {
            return "" + days
        }

    }
    return "00"
}

export function intervalTimeInDays2(datestart, datefinish) {
    let day1 = new Date(datestart)
    let day2 = new Date(datefinish)

    let difference = day2 - day1
    let days = difference / (1000 * 3600 * 24)
    days = days.toFixed(0)
    return days
}

export function Contains(parameter, search) {
    return parameter.toString().toLowerCase().includes(search.toLocaleLowerCase())
}

export function DeleteCharacter(word, character) {
    if (word) {
        let regex = RegExp(character, "g")
        return word.replace(regex, '')
    } else {
        return ""
    }

}

export function ReplaceCharacter(word, character, newCharacter) {
    if (word) {
        let regex = RegExp(character, "g")
        return word.replace(regex, newCharacter)
    } else {
        return ""
    }

}

export function DeleteStrangeCharacter(word) {
    return word.replace(/[^\w\s\-\\&\\//]/gi, '')
}

export function DeleteCharactersNotAccepted(word) {
    return word.replace(/[^a-zA-Z0-9]/g, "")
}

export function NumberFormat(num) {
    const regex = /(\d)(?=(\d{3})+(?!\d))/g;
    const com = '$1,';
    let tempo = num.toString().split('.');
    tempo[0] = tempo[0].replace(regex, com);
    return tempo[1] ? tempo.join('.') : tempo[0];
}

export function MonthInLetters(monthNum) {
    
    switch (Number(monthNum)) {
        case 1:
            return "January"
        case 2:
            return "February"
        case 3:
            return "March"
        case 4:
            return "April"
        case 5:
            return "May"
        case 6:
            return "June"
        case 7:
            return "July"
        case 8:
            return "August"
        case 9:
            return "September"
        case 10:
            return "October"
        case 11:
            return "November"
        case 12:
            return "December"
        case 0:
                return "December"
        default:
            return ""

    }
}

export function OrderArray(arreglo, llave, bandera) {

    const l = arreglo.length;
    if (bandera) {
        for (let i = 0; i < l; i++) {
            for (let j = 0; j < l - 1 - i; j++) {
                if (arreglo[j][llave] > arreglo[j + 1][llave]) {
                    [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                }
            }
        }
    } else {
        for (let i = 0; i < l; i++) {
            for (let j = 0; j < l - 1 - i; j++) {
                if (arreglo[j][llave] < arreglo[j + 1][llave]) {
                    [arreglo[j], arreglo[j + 1]] = [arreglo[j + 1], arreglo[j]];
                }
            }
        }
    }
    return arreglo;
}

export async function getIMGByItemCode(itemCode) {
    const data = {
        idCompany: Number(getValueCookie('CompanyId')),
        ItemCode: itemCode
    }
    const resultImg = await getInformationWithData('/Items/get/Img', data)
    let itemURLImage = ''
    if (resultImg.status.code === 1) {
        if (resultImg.data.itemURLImage) {
            if (resultImg.data.itemURLImage !== '') {
                const date = new Date().getTime()
                itemURLImage = `${resultImg.data.itemURLImage}?${date}`
            } else {
                itemURLImage = ''
            }
        } else {
            itemURLImage = ''
        }
    }

    return itemURLImage
}

export function random_rgba() {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ', 1)';
}

export function AleatoryNumber(min, max) {
    return String(Math.floor((Math.random() * ((max - min) + 1)) + min))
}

export function getPriceMargin(price, cost, priceLevel) {
    let priceMargin = "0"
    if (price && cost) {
        if (price > 0) {

            if (getValueCookie('CompanyId') === '6') {
                cost = cost / 0.95
            }

            priceMargin = (((price - cost) / price) * 100).toFixed(0)
        }
    }
    return String(AleatoryNumber(10000563, 20000013)) + "00" + priceMargin + "00"
}

export function getPurePriceMargin(price, cost) {
    let priceMargin = "0"
    if (price && cost) {
        if (price > 0) {

            if (getValueCookie('CompanyId') === '6') {
                cost = cost / 0.95
            }

            priceMargin = (((price - cost) / price) * 100).toFixed(0)
        }
    }
    return Number(priceMargin)
}

export function decimalToHour(decimal) {
    const horas = Math.floor(decimal);
    const minutos = Math.round((decimal % 1) * 60);
    let hour = (horas <= 9) ? "0" + horas : "" + horas
    let min = (minutos <= 9) ? "0" + minutos : "" + minutos

    const fecha = new Date();
    fecha.setHours(horas, minutos);
    return hour + ":" + min;
}

export function Delay(n) {
    return new Promise(function (resolve) {
        setTimeout(resolve, n * 1000);
    });
}

export async function ItemDesc(array, keyToChange, keyItem, prodLine) {
    if (array.length > 0) {
        const data = {
            company: getValueCookie('Company'),
            idcompany: getValueCookie('CompanyId'),
        }
        const invent = await getInformationWithData('/Items/get', data)
        if (getValueCookie('CompanyId') === '6') {
            data.idcompany = '3'
            data.company = 'Mayaland Atlanta'
            const inventM = await getInformationWithData('/Items/get', data)
            invent.status.code = inventM.status.code
            for (const item of inventM.data) {
                const index = getIndexElement(invent.data, 'itemCode', item.itemCode)
                if (index !== -1) {
                    await invent.data.splice(index, 1)
                }
            }
            invent.data = invent.data.concat(inventM.data)

        }


        if (invent.status.code === 1) {
            let desc = ''
            for (const item of array) {
                const index = getIndexElement(invent.data, 'itemCode', item[keyItem])

                if (index !== -1) {
                    desc = (!(invent.data[index].completeDesc) || invent.data[index].completeDesc === "null") ? invent.data[index].abbreviatedDesc : invent.data[index].completeDesc
                    item.LastDueDate = invent.data[index].LastDueDate
                    if (desc) {
                        item[keyToChange] = desc
                        if (prodLine) {
                            item.OnHand = invent.data[index].quantity ? invent.data[index].quantity : 0
                            item.ProductLine = invent.data[index].ProductLineDesc
                        }
                    }
                } else {
                    if (prodLine) {
                        item.OnHand = 0
                    }
                }
            }

        }
    }

    return array

}

export function MobileDisp() {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/Opera Mini/i)) {
        return false
    }
    return true
}

export function validateNumbersForQuantitys(num) {

    var regex = /^(\d+|(\d*\.)?\d*\.5)$/;
    return regex.test(num);
}
export function DecimalPart(numero) {
    var parteDecimal = numero % 1;
    return parteDecimal;
}

export function generateRandomPassword() {
    const length = 7;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    let password = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
    }

    return password;
};

export function OrderArrayASCDESC(arr, key, orden) {
    return arr.sort(function (a, b) {
        if (a[key] < b[key]) {
            return orden === 'asc' ? -1 : 1;
        }
        if (a[key] > b[key]) {
            return orden === 'asc' ? 1 : -1;
        }
        return 0;
    });
}

export function NameSalesPerson(SalesPerson) {
    switch (SalesPerson) {
        case "AM":
            return "Andrea Martínez"
        case "RR":
            return "Rafael Rivera"
        case "AS":
            return "Luz Hernández"
        case 'KL2':
            return 'Katherine Lopez'
        case "0010":
            return "Corporativo"
        case "0000":
            return "Altitude"
        case "JDL":
            return "Juan David"
        case "TOT":
            return "Totals"
        case "JC":
                return "Josue Contreras"
        case "WW":
            if(getValueCookie('CompanyId')==='3'){
                return "Altitude"
            }
                return "José Celis"
        default:
            return SalesPerson
    }
}

export function filterAndSortData(arr, num) {

    const objetosOrdenados = arr.filter(objeto => {

        return objeto.NumberofDays >= num || objeto.NumberofDays === "Never";
    }).sort((a, b) => {
        if (a.NumberofDays === "Never") return 1; // "Never" se coloca al final
        if (b.NumberofDays === "Never") return -1; // "Never" se coloca al final
        return a.NumberofDays - b.NumberofDays;
    });
    /* this.setState({ Data: objetosOrdenados })
    console.log("obj;", this.state.Data)
    console.log(objetosOrdenados); */
    return objetosOrdenados;
}
export default { intervalTimeInDays, Contains, DeleteCharacter, NumberFormat, MonthInLetters, OrderArray, getIMGByItemCode, random_rgba, AleatoryNumber, getPriceMargin, getPurePriceMargin, MobileDisp, filterAndSortData }