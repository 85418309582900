//Invoices and Spoilage
import React, { Component } from 'react'
import "../css/general-style.css"
import '../css/table-responsive.css'
import LoadingWindow from '../components/LoadingWindow';
import { AiOutlineFileAdd, AiOutlineCloseCircle, AiFillPrinter } from "react-icons/ai";
import CustomerList from '../components/CustomerList';
import Select from 'react-select';
import Catalogue from '../components/Catalogue';
import { getValueCookie } from '../services/cookieService';
import { create_Delete_Update_Information, getInformationWithData, getInformationWithDataGlobalSales, saveJsonData } from '../services/CABE';
import Swal from "sweetalert2";

import SmallModal from '../components/SmallModal';
import { DateFormatMAS90, FormatQueryReturnDateForReport, getActualDateUTC, getDateYearMonthDayDash } from '../functions/dateFormat';
import { OrderPDF } from '../components/OrderPDF';
import { pricesFormat } from '../functions/pricesFormat';
import { getPrintHeaderCompany } from '../functions/companyInformation';
import { getIndexElement } from '../functions/searchInObject';
import InvoiceListSalesPerson from '../components/InvoiceListSalesPerson';
import InvoiceListCustomerNo from '../components/InvoiceListCustomerNo';
import { InvoicePDF } from '../components/InvoicePDF';
import { confirmCloseAlert } from '../functions/alerts';
import SalesOrderPicking from '../components/SalesOrderPicking';
import { DecimalPart, MobileDisp, NumberFormat, OrderArray, validateNumbersForQuantitys } from '../functions/generalFunctions';
import SearchUPC from '../components/SearchUPC';
import { InvoicePDF2 } from '../components/InvoicePDF2';
import Catalogue2 from '../components/Catalogue2.0/Catalogue2.0';
import { InvoiceZebra } from '../components/InvoiceZebra/InvoiceZebra';
import { DownloadInvoicePDF } from '../components/DownloadInvoicePDF';
import { DownloadInvoicePDF2 } from '../components/DownloadInvoicePDF2';


export default class Invoices extends Component {
    constructor(props) {
        super(props);
        this.ModalLoading = React.createRef();
    }


    state = {
        Invoice: {
            InvoiceNo: '',
            InvoiceDate: '',
            BatchNo: '',
            InvoiceType: 'IN',
            Warehouse: '000',
            SalespersonNo: '',
            Comment: '',
            FreightAmt: 0,
            Customer: {
                AddressLine1: '',
                City: '',
                CountryCode: '',
                CreditLimit: 0,
                CustomerName: '',
                CustomerNo: '',
                EmailAddress: '',
                FaxNo: '',
                PriceLevel: '',
                SalespersonNo: '',
                State: '',
                TaxSchedule: '',
                TelephoneNo: '',
                TermsCode: '',
                UDF_RUTA: '',
                ZipCode: '',
            },
            TotalQuantityOrdered: 0,
            TotalCost: 0,
            TotalQuantityShipped: 0,
            TotalWeight: 0,
            SalesOrderNo: '',
            ApplyToInvoiceNo: '',
            Duplicate: false

        },
        Products: [],
        Balance: {
            HeaderBalance: [],
            TermsCode: '',
            TotalBalance: 0,
            SelectedInvoice: '',
            Detail: []
        },
        companyPrintHeader: getPrintHeaderCompany(),
        showModal1: false,
        showModal2: false,
        selectOptions1: [
            { value: 'IN', label: 'Standard' },
            { value: 'CM', label: 'Credit Memo' }
        ],
        selectOptions2: [
            { value: '003', label: 'B&B Miami Warehouse Price Level 4' },//4
            { value: '000', label: 'Mayaland Atlanta Warehouse Price Level 3' }//5
        ],
        temporalBatchNo: '',
        oldInvoice: false,
        enablePrint: true,
        addTitle: '',
        PercentageScanning: 0,
        PercentageManual: 0,
        TotalManual: 0,
        TotalScanning: 0,

    }

    handleModalOpen = (modal) => {

        this.setState({ [modal]: true })
    }

    handleModalClose = async (modal) => {
        if (modal === 'showModal1') {
            if (this.state.Invoice.BatchNo !== '' && this.state.Invoice.BatchNo !== null && this.state.Invoice.BatchNo !== undefined) {
                await this.StartNewInvoice()
                this.setState({ temporalBatchNo: '' })
                this.setState({ [modal]: false })
            }
        } else {
            this.setState({ [modal]: false })
        }
    }

    handleStorageChange = async (event) => {
        if (event.key === 'InvoiceSave') {
            let infoRec = JSON.parse(localStorage.getItem('InvoiceSave'));
            if (infoRec) {
                if (infoRec.Invoice.InvoiceNo === this.state.Invoice.InvoiceNo) {
                    await this.setState({ Products: infoRec.Products, Invoice: infoRec.Invoice })
                    await this.refreshInputs()
                }
            }
        }
    }

    async getNewCorrelativeBatchNumber() {

        const data = {
            type: 'BatchNumber',
            idCompany: getValueCookie('CompanyId')
        }
        const result = await getInformationWithData('/sales/get/correlative', data)
        if (result.status.code === 1) {
            const temporal = "M" + result.data[0].correlative
            document.getElementById('batchNoInputInvoice').value = temporal
            this.setState({ temporalBatchNo: temporal })
        }
    }

    async getNewCorrelativeInvoiceNumber() {

        const data = {
            type: 'InvoiceNumber',
            idCompany: getValueCookie('CompanyId')
        }
        const result = await getInformationWithData('/sales/get/correlative', data)
        if (result.status.code === 1) {
            const temporal = this.state.Invoice
            temporal.InvoiceNo = 'PM' + result.data[0].correlative
            this.setState({ Invoice: temporal })
        }
    }

    async ChangeSalesOrder() {
        const data = {
            SalespersonNo: getValueCookie('SalesPerson'),
            idCompany: getValueCookie('SubCompanyId') ? Number(getValueCookie('SubCompanyId')) : Number(getValueCookie('CompanyId')),
            CustomerNo: '*'
        }
        let respuesta = await getInformationWithData('/invoice/header', data);
        const index = getIndexElement(respuesta.data, 'InvoiceNo', this.state.Invoice.InvoiceNo)

        if (index !== -1) {
            await this.getNewCorrelativeInvoiceNumber()
            const temporal = this.state.Invoice
            if (temporal.BatchNo === '') {
                this.handleModalOpen('showModal1')
            } else {
                Swal.fire({
                    title: 'Do you want change the Batch Number?',
                    showDenyButton: true,
                    confirmButtonText: 'Yes',
                    denyButtonText: `No`,
                    allowOutsideClick: false
                }).then(async (result) => {
                    if (result.isConfirmed) {

                        this.handleModalOpen('showModal1')
                        document.getElementById('batchNoInputInvoice').value = ''



                    }
                })
            }
        }
    }

    BatchNumberModal(moddle) {
        if (this.state.Invoice.InvoiceType === 'IN') {
            this.clearAll()
        }

        const temporal = this.state.Invoice
        if (temporal.BatchNo === '' || temporal.BatchNo === null || temporal.BatchNo === undefined) {
            this.handleModalOpen('showModal1')
        } else {
            Swal.fire({
                title: 'Do you want change the Batch Number?',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
                allowOutsideClick: false
            }).then(async (result) => {
                if (result.isConfirmed) {

                    this.handleModalOpen('showModal1')
                    document.getElementById('batchNoInputInvoice').value = ''



                } else if (result.isDenied) {
                    if (moddle === 'New') {
                        await this.StartNewInvoice()
                    }
                }
            })
        }
    }

    ConfirmBatchNumber() {
        const batchValue = document.getElementById('batchNoInputInvoice').value
        const temporal = this.state.Invoice
        temporal.BatchNo = batchValue
        this.setState({ Invoice: temporal, temporalBatchNo: '' })

        this.StartNewInvoice()


        this.handleModalClose('showModal1')
    }
    ConfirmBatchNumberOld() {
        const batchValue = document.getElementById('batchNoInputInvoice').value
        const temporal = this.state.Invoice
        temporal.BatchNo = batchValue
        this.setState({ Invoice: temporal })
        this.handleModalClose('showModal1')
    }

    async StartNewInvoice() {
        //funcion clear sin el batch
        if (!this.state.oldInvoice) {
            await this.clearAll()
        }

        await this.getNewCorrelativeInvoiceNumber()
        const temporal = this.state.Invoice
        temporal.InvoiceDate = getDateYearMonthDayDash()
        temporal.SalespersonNo = getValueCookie('SalesPerson')
        this.setState({ Invoice: temporal })
    }
    async getDetailBalance(LineBalance) {
        const data = {
            idCompany: Number(getValueCookie('CompanyId')),
            InvoiceNo: LineBalance.InvoiceNo
        }
        this.ModalLoading.current.showState(true);
        const result = await getInformationWithData('/invoice/history/getInvoicePayment', data)
        if (result.status.code === 1) {
            const temporal = this.state.Balance
            temporal.Detail = result.data
            temporal.SelectedInvoice = LineBalance.InvoiceNo
            this.setState({ Balance: temporal })
        }
        this.ModalLoading.current.showState(false);
    }
    getTotalBalance() {
        let currentBalance = 0

        this.state.Balance.HeaderBalance.forEach(line => {
            currentBalance += Number(line.Balance)
        });

        const temporal = this.state.Balance
        temporal.TotalBalance = currentBalance
        this.setState({ Balance: temporal })
    }

    onTarget = async (e, item) => {

        const ident = e.target.id.split('_')
        if (ident.length === 1) {
            const temporal = this.state.Invoice
            if (ident[0] === 'FreightAmtInvoice') {
                temporal.FreightAmt = Number(e.target.value)
                this.setState({ Invoice: temporal })
            } else if (ident[0] === 'CarrierInvoice') {
                temporal.Comment = e.target.value
                this.setState({ Invoice: temporal })
            } else if (ident[0] === 'batchNoInputInvoice') {
                this.setState({ temporalBatchNo: e.target.value })
            }
        } else {
            const temporal = this.state.Products
            const index = temporal.indexOf(item)

            if (index !== -1) {
                if (ident[0] === "quantityShippedInvoice") {
                    let quant = Number(e.target.value)

                    if (!validateNumbersForQuantitys(quant)) {
                        let parteDecimal = 1 - DecimalPart(quant)
                        quant += parteDecimal
                        document.getElementById(e.target.id).value = quant
                    }


                    temporal[index].quantityShipped = Number(quant)
                    temporal[index].totalCost = (Number(quant) * Number(temporal[index].UnitPrice))
                    temporal[index].totalWeight = (Number(quant) * Number(temporal[index].ShipWeight))
                    this.setState({ Products: temporal })
                    this.calculateTotals()
                } else if (ident[0] === "unitPriceInvoice") {
                    temporal[index].UnitPrice = Number(e.target.value)
                    temporal[index].totalCost = (Number(e.target.value) * Number(temporal[index].quantityShipped))
                    this.setState({ Products: temporal })
                    this.calculateTotals()
                }


            }
        }
    }

    onChangeSelects = async (e, id) => {
        if (id === 'Type') {
            await this.clearAll()
        }
        const temporal = this.state.Invoice
        switch (id) {
            case "Type":
                temporal.InvoiceType = e.value
                this.setState({ Invoice: temporal })
                break;
            case "Warehouse":
                temporal.Warehouse = e.value
                this.setState({ Invoice: temporal })
                this.changePriceLevel(e.value)
                break;
            default:
                break;
        }


    }
    changePriceLevel(id) {
        const temporal = this.state.Invoice
        switch (id) {
            case "003":
                temporal.Customer.PriceLevel = '4'
                this.setState({ Invoice: temporal })
                break;
            case "000":
                temporal.Customer.PriceLevel = '3'
                this.setState({ Invoice: temporal })
                break;

            default:
                break;
        }
    }


    calculateTotals() {
        let totalQuantity = 0
        let totalCost = 0
        let totalShipped = 0
        let totalWeight = 0
        const temporal = this.state.Products
        const temporal2 = this.state.Invoice

        for (const item of temporal) {

            totalQuantity += Number(item.quantityOrdered)
            totalCost += Number(pricesFormat(item.totalCost))
            totalShipped += Number(item.quantityShipped)
            totalWeight += Number(item.totalWeight)

        }
        temporal2.TotalQuantityOrdered = totalQuantity
        temporal2.TotalQuantityShipped = totalShipped
        temporal2.TotalWeight = totalWeight
        temporal2.TotalCost = totalCost.toFixed(2)
        this.setState({ Invoice: temporal2 })
        this.AutoSave()
    }
    removeProduct(item) {
        const temporal = this.state.Products
        const index = temporal.indexOf(item)
        if (index !== -1) {
            temporal[index].quantityShipped = 0
            temporal[index].totalCost = 0
            temporal[index].Visible = 0
            temporal[index].totalWeight = 0
            for (let a = 0; a < temporal.length; a++) {
                document.getElementById("quantityShippedInvoice_" + a).value = temporal[a].quantityShipped
                document.getElementById("unitPriceInvoice_" + a).value = temporal[a].UnitPrice
            }
            this.setState({ Products: temporal })
            this.calculateTotals()
        }
    }

    AsignLineSequence() {

        const temporal = OrderArray(JSON.parse(JSON.stringify(this.state.Products)), 'IdCompany', true)


        let count = 1
        for (const item of temporal) {

            let flag = false
            item.LineKey = count
            if ((!(this.state.Invoice.SalesOrderNo))) {
                flag = true
            }
            if (this.state.Invoice.SalesOrderNo === '') {
                flag = true
            }
            if (flag) {


                if (item.Comment !== null && item.Comment !== undefined) {
                    let t = item.Comment.split('||')
                    if (t.length >= 3) {
                        item.Comment = t[0] + "||" + item.RegisterType + "||" + getValueCookie('userName') + "||" + (item.HaveUPC ? '1' : '0')
                    } else {
                        item.Comment += "||" + item.RegisterType + "||" + getValueCookie('userName') + "||" + (item.HaveUPC ? '1' : '0')
                    }


                }
                if (item.RegisterType) {

                }
            }

            count++
        }

        this.setState({ Products: temporal })
        this.calculatePercentageScanning()



    }

    calculatePercentageScanning() {
        const total = this.state.Products.length
        if (total > 0) {
            let realTotal = 0
            let manual = 0
            let scanner = 0
            for (const item of this.state.Products) {
                if (item.Visible === 1) {
                    realTotal++
                    if (item.RegisterType === 's' || (item.quantityShipped > 0 && !item.HaveUPC)) {
                        scanner += 1
                    } else if (item.RegisterType === 'm') {
                        manual += 1
                    }
                }

            }

            let Pmanual = 100 * (manual / realTotal)
            let Pscanning = 100 * (scanner / realTotal)

            this.setState({ TotalManual: manual, TotalScanning: scanner, PercentageManual: Pmanual, PercentageScanning: Pscanning })
        } else {
            this.setState({ TotalManual: 0, TotalScanning: 0, PercentageManual: 0, PercentageScanning: 0 })
        }
    }

    SecurityPrices() {

        for (const item of this.state.Products) {
            if (Number(item.UnitPrice) === 0) {
                return true
            }
        }
        return false
    }


    async SaveInvoice(message, mode) {

        if (this.state.Invoice.InvoiceType === 'CM' && this.state.Invoice.InvoiceNo === '') {
            if (message) {
                Swal.fire({
                    title: 'Attention Please!',
                    icon: 'info',
                    text: 'For the Credit Memo you need associate this with a Open Invoice, Please select one and retry'
                })
            }

        } else {
            const temporal = this.state.Invoice

            // If it's a Mayaland invoice for Altitude, it will update taxschedule
            const cookieCompany = Number(getValueCookie('CompanyId'));
            console.log(cookieCompany)
            if (temporal.Customer.CustomerNo == '0016027' && cookieCompany == 3){
                temporal.Customer.TaxSchedule ='DEFAULT';
                console.log(temporal)
            }


            if (temporal.SalesOrderNo) {
                let SalesOrderNO = temporal.SalesOrderNo.split('-')
                temporal.SalesOrderNo = SalesOrderNO.length > 1 ? SalesOrderNO[0] : temporal.SalesOrderNo

            }

            await this.AsignLineSequence()
            await this.refreshInputs()
            const data = {
                Invoice: temporal,
                Products: this.state.Products,
                OrderType: "Invoice",
                idCompany: Number(getValueCookie('CompanyId')),
                username: getValueCookie('userName'),
                company: getValueCookie('Company')
            }
            await saveJsonData('/store/json', data)

            if (this.SecurityPrices()) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Attention please!',
                    text: 'One or more products have a price $0.00,\nif this is correct please type "Accept"',
                    input: 'text',
                    inputPlaceholder: 'Accept',
                    showCancelButton: true,
                    showConfirmButton: true,
                    willOpen: () => {
                        const input = Swal.getInput()
                        const conf = Swal.getConfirmButton()
                        conf.disabled = true
                        input.oninput = () => {
                            if (input.value === 'Accept') {
                                conf.disabled = false
                            } else {
                                conf.disabled = true
                            }


                        }
                    }
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const result = await create_Delete_Update_Information('/invoice/set', data)
                        await getInformationWithDataGlobalSales('/Sales/setStatus', { SalesOrderNo: data.Invoice.SalesOrderNo, OrderStatus: 7 })
                        if (result.status.code === 1) {
                            localStorage.removeItem('InvoiceSave')
                            if (message) {
                                if (this.state.PercentageManual !== 0 || this.state.PercentageScanning !== 0) {
                                    Swal.fire({
                                        title: 'Order Completed!',
                                        html: `
                                        <div className='tableFixHead'>
                                        <table className='table align-middle'>
                                        <thead className='thead'>
                                            <tr className='text-light text-center'>
                                                <th className='bg-dark'>Type</th>
                                                <th className='bg-dark'>Quantity</th>
                                                <th className='bg-dark'>Percentage</th>
                                            </tr>
                                        </thead>
                                        <tbody className='tbody'>
                                            <tr>
                                            <td>Scanned Products</td>
                                            <td>${this.state.TotalScanning}</td>
                                            <td className='text-end'>${pricesFormat(this.state.PercentageScanning)} %</td>
                                            </tr>
                                            <tr>
                                            <td>Manual Products</td>
                                            <td>${this.state.TotalManual}</td>
                                            <td className='text-end'>${pricesFormat(this.state.PercentageManual)}%</td>
                                            </tr>
                                        </tbody>
                                        <tfoot className='tfoot'>
                                        <tr className='bg-secondary text-light'>
                                        <td>Total</td>
                                        <td>${this.state.TotalManual + this.state.TotalScanning}</td>
                                        <td>${100} %</td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                            </div>
                                            `,
                                        icon: 'success',
                                        showCancelButton: false,
                                        confirmButtonText: 'Ok',

                                    }
                                    )

                                } else {
                                    confirmCloseAlert('correct', 'The Invoice was saved successfully!')
                                }

                                this.setState({ enablePrint: false })
                            }

                            //this.clearAll()
                            await this.OpenPrint(mode)
                        }
                    }
                })
            } else {
                const result = await create_Delete_Update_Information('/invoice/set', data)
                await getInformationWithDataGlobalSales('/Sales/setStatus', { SalesOrderNo: data.Invoice.SalesOrderNo, OrderStatus: 7 })
                if (result.status.code === 1) {
                    localStorage.removeItem('InvoiceSave')
                    if (message) {
                        if (this.state.PercentageManual !== 0 || this.state.PercentageScanning !== 0) {
                            Swal.fire({
                                title: 'Order Completed!',
                                html: `
                            <div className='tableFixHead'>
                            <table className='table align-middle'>
                            <thead className='thead'>
                                <tr className='text-light text-center'>
                                    <th className='bg-dark'>Type</th>
                                    <th className='bg-dark'>Quantity</th>
                                    <th className='bg-dark'>Percentage</th>
                                </tr>
                            </thead>
                            <tbody className='tbody'>
                                <tr>
                                <td>Scanned Products</td>
                                <td>${this.state.TotalScanning}</td>
                                <td className='text-end'>${pricesFormat(this.state.PercentageScanning)} %</td>
                                </tr>
                                <tr>
                                <td>Manual Products</td>
                                <td>${this.state.TotalManual}</td>
                                <td className='text-end'>${pricesFormat(this.state.PercentageManual)}%</td>
                                </tr>
                            </tbody>
                            <tfoot className='tfoot'>
                            <tr className='bg-secondary text-light'>
                            <td>Total</td>
                            <td>${this.state.TotalManual + this.state.TotalScanning}</td>
                            <td>${100} %</td>
                            </tr>
                            </tfoot>
                        </table>
                                </div>
                                `,
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonText: 'Ok',
                            }
                            )
                        } else {
                            confirmCloseAlert('correct', 'The Invoice was saved successfully!')
                        }
                        this.setState({ enablePrint: false })
                    }

                    //this.clearAll()
                    await this.OpenPrint(mode)
                }
            }
        }
    }

    refreshInputs() {
        const temporal = this.state.Products
        document.getElementById("FreightAmtInvoice").value = this.state.Invoice.FreightAmt
        document.getElementById("CarrierInvoice").value = this.state.Invoice.Comment
        for (let a = 0; a < temporal.length; a++) {
            document.getElementById("quantityShippedInvoice_" + a).value = temporal[a].quantityShipped
            document.getElementById("unitPriceInvoice_" + a).value = pricesFormat(temporal[a].UnitPrice)
        }
    }

    clearAll() {
        const temporal = this.state.Invoice.BatchNo
        localStorage.removeItem('InvoiceSave')
        this.setState({
            Invoice: {
                InvoiceNo: '',
                InvoiceDate: '',
                BatchNo: temporal,
                InvoiceType: 'IN',
                Warehouse: '000',
                SalespersonNo: '',
                Comment: '',
                FreightAmt: 0,
                Customer: {
                    AddressLine1: '',
                    City: '',
                    CountryCode: '',
                    CreditLimit: 0,
                    CustomerName: '',
                    CustomerNo: '',
                    EmailAddress: '',
                    FaxNo: '',
                    PriceLevel: '',
                    SalespersonNo: '',
                    State: '',
                    TaxSchedule: '',
                    TelephoneNo: '',
                    TermsCode: '',
                    UDF_RUTA: '',
                    ZipCode: '',
                },
                TotalQuantityOrdered: 0,
                TotalCost: 0,
                TotalWeight: 0,
                TotalQuantityShipped: 0,
                SalesOrderNo: '',
                ApplyToInvoiceNo: '',
                Duplicate: false
            },
            Products: [],
            Balance: {
                HeaderBalance: [],
                TermsCode: '',
                TotalBalance: 0,
                SelectedInvoice: '',
                Detail: []
            },
            oldInvoice: false,
            temporalBatchNo: '',
            enablePrint: true,
            addTitle: '',
            PercentageScanning: 0,
            PercentageManual: 0,
            TotalManual: 0,
            TotalScanning: 0,
        })
    }

    partialClear() {
        const temporal = this.state.Invoice

        this.setState({
            Invoice: {
                InvoiceNo: '',
                InvoiceDate: '',
                BatchNo: temporal.BatchNo,
                Warehouse: temporal.Warehouse,
                InvoiceType: temporal.InvoiceType,
                SalespersonNo: '',
                Comment: '',
                FreightAmt: 0,
                Customer: temporal.Customer,
                TotalQuantityOrdered: 0,
                TotalCost: 0,
                TotalWeight: 0,
                TotalQuantityShipped: 0,
                SalesOrderNo: '',
                ApplyToInvoiceNo: '',
                Duplicate: false
            },
            Products: [],
            oldInvoice: false,
            temporalBatchNo: '',
            enablePrint: true,
            PercentageScanning: 0,
            PercentageManual: 0,
            TotalManual: 0,
            TotalScanning: 0,
        })
    }

    realLongDetail() {
        let cont = 0
        this.state.Products.forEach(element => {
            if (element.Visible === 1 && element.quantityShipped > 0) {
                cont++
            }
        });
        return cont
    }

    printDetail(type) {
        let detail = []
        if (type === 'Zebra') {
            this.state.Products.forEach(element => {

                if (element.Visible === 1 && element.quantityShipped !== 0) {
                    element.abbreviatedDesc = (element.completeDesc && element.completeDesc !== "null") ? element.completeDesc : element.abbreviatedDesc
                    element.InvoiceDesc = element.abbreviatedDesc + " @ $ " + pricesFormat(NumberFormat(element.UnitPrice))
                    let upc = 0
                    if (element.caseupc !== "null" && element.caseupc !== "" && element.caseupc !== "undefined" && element.caseupc) {
                        upc = element.caseupc
                    } else if (element.upc !== "null" && element.upc !== "" && element.upc !== "undefined" && element.upc) {
                        upc = element.upc

                    } else if (element.upc2 !== "null" && element.upc2 !== "" && element.upc2 !== "undefined" && element.upc2) {
                        upc = element.upc2
                    }
                    element.upc = upc
                    detail.push(element)
                }
            });
        } else {
            this.state.Products.forEach(element => {

                if (element.Visible === 1 && element.quantityShipped !== 0) {
                    element.abbreviatedDesc = (element.completeDesc && element.completeDesc !== "null") ? element.completeDesc : element.abbreviatedDesc

                    let upc = 0
                    if (element.caseupc !== "null" && element.caseupc !== "" && element.caseupc !== "undefined" && element.caseupc) {
                        upc = element.caseupc
                    } else if (element.upc !== "null" && element.upc !== "" && element.upc !== "undefined" && element.upc) {
                        upc = element.upc

                    } else if (element.upc2 !== "null" && element.upc2 !== "" && element.upc2 !== "undefined" && element.upc2) {
                        upc = element.upc2
                    }
                    element.upc = upc
                    detail.push(element)
                }
            });
            const tamBreak = getValueCookie("CompanyId") === '3' ? 55 : 45
            const res = detail.length % tamBreak
            for (let a = 0; a < tamBreak - res; a++) {
                const itemJson = {
                    itemCode: ' ',
                    abbreviatedDesc: ' ',
                    upc: ' ',
                    quantity: ' ',
                    ShipWeight: ' ',
                    StandardUnitCost: ' ',
                    LastTotalUnitCost: ' ',
                    UnitPrice: ' ',
                    upc2: ' ',
                    caseupc: ' ',
                    BIN: " ",
                    totalWeight: ' ',
                    Comment: " ",
                    unitCost: ' ',
                    quantityOrdered: ' ',
                    quantityReceived: ' ',
                    quantityShipped: ' ',
                    totalCost: ' ',
                    totalFreight: ' ',
                    Visible: ' ',
                    BatchUnits: ' ',
                    DiscountUnits: ' ',
                    SuggestedDiscount: ' '
                }
                detail.push(itemJson)

            }

        }




        return detail
    }

    async cancelWithBatchNo() {
        await this.StartNewInvoice()
        this.setState({ temporalBatchNo: '' })
        this.handleModalClose('showModal1')
    }

    textTermsCode(num) {
        switch (Number(num)) {
            case 0:
                return 'Pay in Advance'
            case 1:
                return 'FOB Taiwan'
            case 7:
                return 'Net 07 Days'
            case 10:
                return 'Net 10 Days'
            case 15:
                return 'Net 15 Days'
            case 21:
                return 'Net 21 Days'
            case 30:
                return 'Net 30 Days'
            case 45:
                return 'Net 45 Days'
            default:
                return ''
        }

    }

    AutoSave() {
        localStorage.setItem('InvoiceSave', JSON.stringify(this.state))
    }
    componentDidMount() {
        window.addEventListener('storage', this.handleStorageChange);
        if (localStorage.getItem('InvoiceSave')) {
            let infoRec = JSON.parse(localStorage.getItem('InvoiceSave'));
            if (infoRec) {
                this.setState({ Products: infoRec.Products, Invoice: infoRec.Invoice })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('storage', this.handleStorageChange);
    }

    PrintAndSave(mode) {

        if (this.state.addTitle !== 'REPRINT-') {
            this.SaveInvoice(true, mode)

        } else {
            this.OpenPrint(mode)

        }
    }

    OpenPrint(mode) {

        let button
        if (mode === "Prices") {
            if (document.getElementById('InvoicePrint')) {
                button = document.getElementById('InvoicePrint')
                button.click()
            }

        } else {
            if (document.getElementById('InvoiceWPrint')) {
                button = document.getElementById('InvoiceWPrint')
                button.click()

            }
        }

    }

    render() {
        return (
            <div>

                <LoadingWindow ref={this.ModalLoading} />
                <p className='text-center display-1 pb-2' >Invoice</p>
                <div className='row pb-2'>
                    <div className='col-sm-1'></div>
                    <div className='col-sm-10'>
                        <div className='row pb-5'>
                            <div className='col-sm-6'>
                                <div className='row'>



                                    {this.state.Invoice.InvoiceType === 'CM' ?
                                        <>

                                            <div className="col-sm-12 input-group input-group-lg">
                                                <input type="text" autoComplete='off' id='search1CustomerListIN' className="form-control" placeholder='Search Customer...' />
                                            </div>
                                            <div className='col-sm-12 pt-2 text-center pb-3'>
                                                <CustomerList hidden={this.state.Invoice.BatchNo ? false : true} disabled={(this.state.Invoice.InvoiceNo === '' && this.state.Invoice.InvoiceType === 'IN') || this.state.addTitle !== ''} headerName='Customer' nombrePadre="Invoice" ident="InvoiceCustomerList1" Padre={this} />
                                            </div>

                                            <div className='col-6 pt-3 InputTextBolder'>
                                                <p>Invoice No:</p>
                                            </div>
                                            <div className='col-6 pt-3 InputText text-start'>
                                                <p>{this.state.Invoice.InvoiceNo}</p>
                                            </div>
                                            <div className='col-6 pt-3 InputTextBolder'>
                                                <p>Batch No:</p>
                                            </div>
                                            <div className='col-6 pt-3 InputText text-start'>
                                                <p>{this.state.Invoice.BatchNo}</p>
                                            </div>
                                            <div className='col-6 pt-3 InputTextBolder'>
                                                <p>Sales Person:</p>
                                            </div>
                                            <div className='col-6 pt-3 InputText text-start'>
                                                <p>{this.state.Invoice.SalespersonNo}</p>
                                            </div>
                                            <div className='col-6 pt-3 InputTextBolder'>
                                                <p>Order Date:</p>
                                            </div>
                                            <div className='col-6 pt-3 InputText text-start'>
                                                <p>{DateFormatMAS90(this.state.Invoice.InvoiceDate)}</p>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='col-sm-12 d-grid gap-2'>
                                                <button hidden={this.state.Invoice.InvoiceType === 'CM'} type="button" className="btn greenButton btn-lg" onClick={() => this.BatchNumberModal('New')}>New Invoice <AiOutlineFileAdd /></button>
                                                <InvoiceListSalesPerson disabled={false} headerName='' nombrePadre="InvoiceListSalesPerson" Padre={this} />
                                                <InvoiceListSalesPerson disabled={false} headerName='' nombrePadre="InvoiceListSalesPersonHistory" Padre={this} />
                                            </div>
                                            <div className='col-6 pt-3 InputTextBolder'>
                                                <p>Invoice No:</p>
                                            </div>
                                            <div className='col-6 pt-3 InputText text-start'>
                                                <p>{this.state.Invoice.InvoiceNo}</p>
                                            </div>
                                            <div className='col-6 pt-3 InputTextBolder'>
                                                <p>Batch No:</p>
                                            </div>
                                            <div className='col-6 pt-3 InputText text-start'>
                                                <p>{this.state.Invoice.BatchNo}</p>
                                            </div>
                                            <div className='col-6 pt-3 InputTextBolder'>
                                                <p>Sales Person:</p>
                                            </div>
                                            <div className='col-6 pt-3 InputText text-start'>
                                                <p>{this.state.Invoice.SalespersonNo}</p>
                                            </div>
                                            <div className='col-6 pt-3 InputTextBolder'>
                                                <p>Order Date:</p>
                                            </div>
                                            <div className='col-6 pt-3 InputText text-start'>
                                                <p>{DateFormatMAS90(this.state.Invoice.InvoiceDate)}</p>
                                            </div>
                                            <div className="col-sm-12 input-group input-group-lg">
                                                <input type="text" autoComplete='off' id='search1CustomerListIN' className="form-control" placeholder='Search Customer...' />
                                            </div>
                                            <div className='col-sm-12 pt-2 text-center'>
                                                <CustomerList disabled={this.state.Invoice.InvoiceNo === '' && this.state.Invoice.InvoiceType === 'IN'} headerName='Customer' nombrePadre="Invoice" ident="InvoiceCustomerList1" Padre={this} />
                                            </div>
                                        </>
                                    }
                                </div>

                            </div>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className='pt-2 col-sm-12 InputTextBolder'>
                                        <p>Type:</p>
                                    </div>
                                    <div className=' pt-2 col-sm-12'>
                                        <Select value={this.state.selectOptions1[(getIndexElement(this.state.selectOptions1, 'value', this.state.Invoice.InvoiceType))]} isDisabled={false} className='fs-4 text-start' options={this.state.selectOptions1} onChange={(e) => this.onChangeSelects(e, "Type")} placeholder="Select Type" />
                                    </div>
                                    <div className="pt-3 col-sm-12 d-grid gap-2">
                                        <SalesOrderPicking hidden={this.state.Invoice.BatchNo ? false : this.state.Invoice.InvoiceType === 'CM'} disabled={this.state.Invoice.InvoiceNo === ''} headerName='' nombrePadre="SalesOrderListInvoice" Padre={this} />
                                    </div>
                                    <div hidden={this.state.Invoice.InvoiceType === 'CM'} className='col-6 pt-3 InputTextBolder'>
                                        <p>Sales Order:</p>
                                    </div>
                                    <div hidden={this.state.Invoice.InvoiceType === 'CM'} className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Invoice.SalesOrderNo}</p>
                                    </div>
                                    <div hidden={this.state.Invoice.InvoiceType === 'IN'} className='col-6 pt-3 InputTextBolder'>
                                        <p>Apply to Invoice No:</p>
                                    </div>
                                    <div hidden={this.state.Invoice.InvoiceType === 'IN'} className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Invoice.ApplyToInvoiceNo}</p>
                                    </div>
                                    <div className='col-sm-12 d-grid gap-2'>
                                        <InvoiceListCustomerNo hidden={this.state.Invoice.InvoiceType === 'IN'} disabled={this.state.Invoice.Customer.CustomerNo === ''} CustomerNo={this.state.Invoice.Customer.CustomerNo} headerName='' buttonTitle="Select Invoice to Apply" nombrePadre="InvoiceListCustomer" Padre={this} />

                                    </div>
                                    <div className='col-sm-12 pt-3 InputTextBolder'>
                                        <p>Freight:</p>
                                    </div>
                                    <div className="col-sm-12 pt-1 input-group input-group-lg flex-nowrap">
                                        <span className="input-group-text">$</span>
                                        <input disabled={this.state.Invoice.InvoiceNo === ''} className="form-control form-control-lg text-center" id='FreightAmtInvoice' type="number" min={0.00} defaultValue={this.state.Invoice.FreightAmt} step={0.01} onChange={(e) => this.onTarget(e, '')} />
                                    </div>
                                    <div className='col-sm-12 pt-3 InputTextBolder'>
                                        <p>Carrier:</p>
                                    </div>
                                    <div className="col-sm-12 input-group input-group-lg">
                                        <input disabled={this.state.Invoice.InvoiceNo === ''} type="text" autoComplete='off' id='CarrierInvoice' className="form-control" defaultValue={this.state.Invoice.Comment} onChange={(e) => this.onTarget(e, '')} placeholder='Carrier...' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row grayBackground'>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>Customer No:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Invoice.Customer.CustomerNo}</p>
                                    </div>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>Name:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Invoice.Customer.CustomerName}</p>
                                    </div>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>Zip Code:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Invoice.Customer.ZipCode}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='row'>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>State:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Invoice.Customer.State}</p>
                                    </div>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>City:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Invoice.Customer.City}</p>
                                    </div>
                                    <div className='col-6 pt-3 InputTextBolder'>
                                        <p>Telephone No:</p>
                                    </div>
                                    <div className='col-6 pt-3 InputText text-start'>
                                        <p>{this.state.Invoice.Customer.TelephoneNo}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div hidden={Number(getValueCookie('SubCompanyId')) !== 5} className='row pt-3'>
                            <div className='col-sm-12 InputTextBolder'>
                                <p>Warehouse:</p>
                            </div>
                            <div className=' pt-2 col-sm-12'>
                                <Select value={this.state.selectOptions2[(getIndexElement(this.state.selectOptions2, 'value', this.state.Invoice.Warehouse))]} isDisabled={false} className='fs-4 text-start' options={this.state.selectOptions2} onChange={(e) => this.onChangeSelects(e, "Warehouse")} placeholder="Select Warehouse" />
                            </div>
                        </div>

                        <div className='row pt-5'>
                            <div className="col-sm-12 input-group input-group-lg">
                                <SearchUPC disabled={this.state.Invoice.Customer.CustomerNo === '' || this.state.addTitle !== ''} ComponentName={'Invoice'} Padre={this} priceLevel={this.state.Invoice.Customer.PriceLevel} />
                            </div>
                            <div className='col-sm-12 pt-2 pb-4'>
                                <Catalogue2 id='Catalogue2' CustomerNo={this.state.Invoice.Customer.CustomerNo} colorButton='blueButton' titleButton='Open Catalogue' PriceLevel={this.state.Invoice.Customer.PriceLevel} Array={'Products'} disabled={this.state.Invoice.Customer.CustomerNo === '' || this.state.addTitle !== ''} nombrePadre={"Invoice"} Padre={this} needPrice={getValueCookie('SalesPerson') === '0010' ? false : true} needCost={getValueCookie('SalesPerson') === '0010' ? true : false} />
                            </div>
                        </div>

                    </div>
                    <div className='col-sm-1'></div>
                    <div className='col-sm-12 tableFixHead'>
                        <table className='table align-middle'>
                            <thead className='thead'>
                                <tr className='bg-primary text-white'>
                                    <th className='text-center bg-primary '>Item Code</th>
                                    <th className='text-center bg-primary '>Description</th>
                                    <th className='text-center bg-primary '>Quantity Ordered</th>
                                    <th className='text-center bg-primary '>Quantity Shipped</th>
                                    <th className='text-center bg-primary '>Total Weight (lbs)</th>
                                    <th className='text-center bg-primary '>Unit Price</th>
                                    <th className='text-center bg-primary '></th>
                                    <th className='text-center bg-primary '>Total Order</th>
                                    <th className='text-center bg-primary '></th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.Products.map((item, i) => (
                                        <tr className={(item.UnitPrice <= 0 ? 'bg-warning' : '') + (item.Duplicate)} key={i} hidden={item.Visible === 0}>
                                            <td className='text-start'>{item.itemCode}</td>
                                            <td className='text-start'>{(item.completeDesc && item.completeDesc !== "null") ? item.completeDesc : item.abbreviatedDesc}</td>
                                            <td className='text-center textTableSize'>
                                                {item.quantityOrdered}
                                            </td>
                                            <td>
                                                <div className="input-group  input-group-lg">
                                                    <input disabled={this.state.addTitle !== ''} type="number" id={'quantityShippedInvoice_' + i} min={0} defaultValue={item.quantityShipped} onChange={(e) => this.onTarget(e, item)} className="form-control text-center inputResize" />
                                                </div>
                                            </td>
                                            <td className='text-center textTableSize text-secondary'>
                                                {NumberFormat(Number(item.totalWeight).toFixed(2)) + ' lbs'}
                                            </td>
                                            <td >
                                                <div className="input-group input-group-lg flex-nowrap">
                                                    <span className="input-group-text">$</span>
                                                    <input disabled={this.state.addTitle !== ''} type="number" id={'unitPriceInvoice_' + i} min={0} step={0.01} pattern={/([0-9]+.{0,1}[0-9]*,{0,1})*[0-9]/} defaultValue={Number(item.UnitPrice).toFixed(2)} onChange={(e) => this.onTarget(e, item)} className="form-control text-end text-success inputResize" />
                                                </div>
                                            </td>
                                            <td className='text-end textTableSize text-success'>$</td>
                                            <td className='text-end textTableSize text-success'>
                                                {NumberFormat(Number(item.totalCost).toFixed(2))}
                                            </td>
                                            <td>
                                                <div className="d-grid gap-2">
                                                    <button disabled={this.state.addTitle !== ''} type="button" onClick={() => this.removeProduct(item)} className="btn redButton btn-lg"><AiOutlineCloseCircle /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                            <tfoot className='tfoot'>
                                <tr className='bg-dark text-white'>
                                    <td></td>
                                    <td className='text-center textTableSize'>Totals</td>
                                    <td className='text-center textTableSize'>{this.state.Invoice.TotalQuantityOrdered}</td>
                                    <td className='text-center textTableSize'>{this.state.Invoice.TotalQuantityShipped}</td>
                                    <td className='text-center textTableSize'>{NumberFormat(pricesFormat(this.state.Invoice.TotalWeight)) + ' lbs'}</td>
                                    <td className='text-end textTableSize'></td>
                                    <td className='text-end textTableSize'>$</td>
                                    <td className='text-end textTableSize'>{NumberFormat(pricesFormat(this.state.Invoice.TotalCost))}</td>
                                    <td></td>

                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className='col-sm-1'></div>
                    <div className='col-sm-10'>
                        <div className='row'>
                            {/*
                            <div className='col-6 d-grid gap-2'>
                                <button hidden={this.state.Invoice.BatchNo ? false : true} type="button" className="btn greenButton btn-lg" onClick={() => this.SaveInvoice(true)}>Save Invoice <AiOutlineFileAdd /></button>
                            </div>*/}
                            <div className='col-sm-12 d-grid gap-2'>
                                <button hidden={this.state.Invoice.BatchNo ? false : (this.state.addTitle !== '' ? false : true)} type="button" className="btn orangeButton btn-lg" onClick={() => this.PrintAndSave("Prices")}>{this.state.addTitle !== 'REPRINT-' ? "Invoice Save and Print" : "Invoice Print"} <AiFillPrinter /></button>
                                <InvoiceZebra
                                    id={'InvoicePrintZebra'}
                                    hidden={true}
                                    colorButton='redButton'
                                    titleButton='Invoice Print'
                                    title='Invoice Print'
                                    companyLogo={getValueCookie('DocumentsLogo')}
                                    InvoiceType={this.state.addTitle + (this.state.Invoice.InvoiceType === 'IN' ? 'Invoice' : 'Credit Memo')}
                                    CompanyContactInfo={['Altitude LLC', '7055 Amwiler Industrial Dr', 'Suite B-C', '(770) 871-5807']}
                                    InvoiceHeader={[['Invoice Number:', this.state.Invoice.InvoiceNo], ['Date:', DateFormatMAS90(this.state.Invoice.InvoiceDate)], ['Grocer:', this.state.Invoice.SalespersonNo], ['Client No:', this.state.Invoice.Customer.CustomerNo], ['Client Name:', this.state.Invoice.Customer.CustomerName], ['Address:', this.state.Invoice.Customer.AddressLine1 + ' ' + this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]]}
                                    DescriptionKeys={["quantityShipped", "InvoiceDesc", "$", "totalCost"]}
                                    HeaderDescritpion={["Qty", "Description", "", "Amount"]}
                                    StyleDetail={["QuantityColumn", "LongColumn", "SignColumn", "PriceColumn"]}
                                    Detail={this.printDetail("Zebra")}
                                    quantSignDolar={this.realLongDetail()}
                                    keysDecimalFormat={["totalCost"]}
                                    bottomInformation={[
                                        ["", "Net Invoice: ", "$", NumberFormat(pricesFormat(this.state.Invoice.TotalCost))]
                                        , ["", "Freight: ", "$", NumberFormat(pricesFormat(this.state.Invoice.FreightAmt))]
                                        , [NumberFormat(this.state.Invoice.TotalQuantityShipped), "TOTAL", "$", NumberFormat(pricesFormat(Number(this.state.Invoice.FreightAmt) + Number(this.state.Invoice.TotalCost)))]]}
                                    bottomMessage={"NOTE: Returned checks will incur an additional charge of $50.00.\nLos cheques rechazados tendran un cargo adicional de $50.00.\nNo Returns after 24 hours after delivered.\nNo se aceptan devoluciones despues de 24 horas de entregado."}

                                />
                                {getValueCookie('CompanyId') !== '6' ?
                                    (MobileDisp() ?
                                        <InvoicePDF
                                            id={'InvoicePrint'}
                                            hidden={true}
                                            colorButton='orangeButton'
                                            titleButton='Invoice Print'
                                            title='Invoice Print'
                                            CompanyTitle='Mayaland'
                                            InvoiceType={this.state.addTitle + (this.state.Invoice.InvoiceType === 'IN' ? 'Invoice' : 'Credit Memo')}
                                            CompanyContactInfo={['Mayaland LLC', '7055 Amwiler Industrial Dr', 'Suite B\n Atlanta, GA 30360', '(678) 213-1226']}
                                            InvoiceHeader={['Invoice Number:', this.state.Invoice.InvoiceNo, 'Invoice Date:', DateFormatMAS90(this.state.Invoice.InvoiceDate), 'Sales Person:', this.state.Invoice.SalespersonNo, 'Customer Number:', this.state.Invoice.Customer.CustomerNo, 'Batch No:', this.state.Invoice.BatchNo]}
                                            Box1={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                            Box2={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                            HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", this.textTermsCode(this.state.Invoice.Customer.TermsCode)]}
                                            DescriptionKeys={["quantityShipped", "itemCode", "upc", "abbreviatedDesc", "$", "UnitPrice", "$", "totalCost"]}
                                            HeaderDescritpion={["Qty", "Code", "SKU", "Description", "", "Price", "", "Amount"]}
                                            StyleDetail={["ColumnaCantidades", "ColumnaNormal", "ColumnaNormalCenter", "ColumnaGrande", "Simbolo", "ColumnaMonetaria", "Simbolo", "ColumnaMonetariaRight"]}
                                            Detail={this.printDetail()}
                                            breakpoint={55}
                                            quantSignDolar={this.realLongDetail()}
                                            keysDecimalFormat={["UnitPrice", "totalCost"]}
                                            bottomInformation={[
                                                NumberFormat(this.state.Invoice.TotalQuantityShipped), "", "Cash", "", "Check", "", "", "Open     Weight: " + NumberFormat(pricesFormat(this.state.Invoice.TotalWeight)), "Net Invoice: ", "$", NumberFormat(pricesFormat(this.state.Invoice.TotalCost)),
                                                "", "", "", "", "", "", "", "", "Freight: ", "$", NumberFormat(pricesFormat(this.state.Invoice.FreightAmt)),
                                                "", "", "", "", "", "", "", "", "Invoice Total: ", "$", NumberFormat(pricesFormat(Number(this.state.Invoice.FreightAmt) + Number(this.state.Invoice.TotalCost)))
                                            ]}
                                            bottomStyle={[
                                                "NormalButtom", "CheckButtom", "NormalButtom", "CheckButtom", "NormalButtom", "LineButtom", "CheckButtom", "LargeButtom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                                "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                                "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom3Left", "DolarButtomLeft2", "LineButtom3",
                                            ]}
                                            bottomMessage={"Comment:" + this.state.Invoice.Comment + "\nNo Returns after 1 day, No se aceptan devoluciones despues de 1 dia.\nAll returned checks will be subject to a $30 precessing fee. Todo cheque devuelto tendra un cargo de $30."}
                                        />
                                        :
                                        <DownloadInvoicePDF
                                            id={'InvoicePrint'}
                                            hidden={true}
                                            colorButton='orangeButton'
                                            titleButton='Invoice Print'
                                            title='Invoice Print'
                                            CompanyTitle='Mayaland'
                                            InvoiceType={this.state.addTitle + (this.state.Invoice.InvoiceType === 'IN' ? 'Invoice' : 'Credit Memo')}
                                            CompanyContactInfo={['Mayaland LLC', '7055 Amwiler Industrial Dr', 'Suite B\n Atlanta, GA 30360', '(678) 213-1226']}
                                            InvoiceHeader={['Invoice Number:', this.state.Invoice.InvoiceNo, 'Invoice Date:', DateFormatMAS90(this.state.Invoice.InvoiceDate), 'Sales Person:', this.state.Invoice.SalespersonNo, 'Customer Number:', this.state.Invoice.Customer.CustomerNo, 'Batch No:', this.state.Invoice.BatchNo]}
                                            Box1={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                            Box2={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                            HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", this.textTermsCode(this.state.Invoice.Customer.TermsCode)]}
                                            DescriptionKeys={["quantityShipped", "itemCode", "SKU", "abbreviatedDesc", "$", "UnitPrice", "$", "totalCost"]}
                                            HeaderDescritpion={["Qty", "Code", "SKU", "Description", "", "Price", "", "Amount"]}
                                            StyleDetail={["ColumnaCantidades", "ColumnaNormal", "ColumnaNormalCenter", "ColumnaGrande", "Simbolo", "ColumnaMonetaria", "Simbolo", "ColumnaMonetariaRight"]}
                                            Detail={this.printDetail()}
                                            breakpoint={55}
                                            quantSignDolar={this.realLongDetail()}
                                            keysDecimalFormat={["UnitPrice", "totalCost"]}
                                            bottomInformation={[
                                                NumberFormat(this.state.Invoice.TotalQuantityShipped), "", "Cash", "", "Check", "", "", "Open     Weight: " + NumberFormat(pricesFormat(this.state.Invoice.TotalWeight)), "Net Invoice: ", "$", NumberFormat(pricesFormat(this.state.Invoice.TotalCost)),
                                                "", "", "", "", "", "", "", "", "Freight: ", "$", NumberFormat(pricesFormat(this.state.Invoice.FreightAmt)),
                                                "", "", "", "", "", "", "", "", "Invoice Total: ", "$", NumberFormat(pricesFormat(Number(this.state.Invoice.FreightAmt) + Number(this.state.Invoice.TotalCost)))
                                            ]}
                                            bottomStyle={[
                                                "NormalButtom", "CheckButtom", "NormalButtom", "CheckButtom", "NormalButtom", "LineButtom", "CheckButtom", "LargeButtom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                                "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                                "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom3Left", "DolarButtomLeft2", "LineButtom3",
                                            ]}
                                            bottomMessage={"Comment:" + this.state.Invoice.Comment + "\nNo Returns after 1 day, No se aceptan devoluciones despues de 1 dia.\nAll returned checks will be subject to a $30 precessing fee. Todo cheque devuelto tendra un cargo de $30."}
                                            docname={this.state.Invoice.InvoiceNo}
                                        />
                                    )
                                    :
                                    (
                                        MobileDisp() ?
                                            <InvoicePDF2

                                                id={'InvoicePrint'}
                                                hidden={true}
                                                colorButton='orangeButton'
                                                titleButton='Invoice Print'
                                                title='Invoice Print'
                                                companyLogo={getValueCookie('DocumentsLogo')}
                                                InvoiceType={this.state.addTitle + (this.state.Invoice.InvoiceType === 'IN' ? 'Invoice' : 'Credit Memo')}
                                                CompanyContactInfo={['Altitude LLC', '7055 Amwiler Industrial Dr', 'Suite B-C', '(770) 871-5807']}
                                                InvoiceHeader={['Invoice Number:', this.state.Invoice.InvoiceNo, 'Date:', DateFormatMAS90(this.state.Invoice.InvoiceDate), 'Grocer:', this.state.Invoice.SalespersonNo, 'Client No:', this.state.Invoice.Customer.CustomerNo, 'Batch No:', this.state.Invoice.BatchNo]}
                                                Box1={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                Box2={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", this.textTermsCode(this.state.Invoice.Customer.TermsCode)]}
                                                DescriptionKeys={["quantityShipped", "itemCode", "upc", "abbreviatedDesc", "$", "UnitPrice", "$", "totalCost"]}
                                                HeaderDescritpion={["Quantity", "Code", "SKU", "Description", "", "    Price", "", "Amount"]}
                                                StyleDetail={["ColumnaCantidades", "ColumnaNormal", "ColumnaNormal", "ColumnaGrande", "Simbolo", "ColumnaMonetaria", "Simbolo", "ColumnaMonetariaRight"]}
                                                Detail={this.printDetail()}
                                                breakpoint={45}
                                                quantSignDolar={this.realLongDetail()}
                                                keysDecimalFormat={["UnitPrice", "totalCost"]}
                                                bottomInformation={[
                                                    NumberFormat(this.state.Invoice.TotalQuantityShipped), "", "Cash", "", "Check", "", "", "Open     Weight: " + NumberFormat(pricesFormat(this.state.Invoice.TotalWeight)), "Net Invoice: ", "$", NumberFormat(pricesFormat(this.state.Invoice.TotalCost)),
                                                    "", "", "", "", "", "", "", "", "Freight: ", "$", NumberFormat(pricesFormat(this.state.Invoice.FreightAmt)),
                                                    "", "", "", "", "", "", "", "", "Invoice Total: ", "$", NumberFormat(pricesFormat(Number(this.state.Invoice.FreightAmt) + Number(this.state.Invoice.TotalCost)))
                                                ]}
                                                bottomStyle={[
                                                    "NormalButtom", "CheckButtom", "NormalButtom", "CheckButtom", "NormalButtom", "LineButtom", "CheckButtom", "LargeButtom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom3Left", "DolarButtomLeft2", "LineButtom3",
                                                ]}
                                                bottomMessage={"Returned checks will incur an additional charge of $50.00. Los cheques rechazados tendran un cargo adicional de $50.00. \nNo Returns after 24 hours after delivered, No se aceptan devoluciones despues de 24 horas de entregado."}

                                            />
                                            :
                                            <DownloadInvoicePDF2
                                                id={'InvoicePrint'}
                                                hidden={true}
                                                colorButton='orangeButton'
                                                titleButton='Invoice Print'
                                                title='Invoice Print'
                                                companyLogo={getValueCookie('DocumentsLogo')}
                                                InvoiceType={this.state.addTitle + (this.state.Invoice.InvoiceType === 'IN' ? 'Invoice' : 'Credit Memo')}
                                                CompanyContactInfo={['Altitude LLC', '7055 Amwiler Industrial Dr', 'Suite B-C', '(770) 871-5807']}
                                                InvoiceHeader={['Invoice Number:', this.state.Invoice.InvoiceNo, 'Date:', DateFormatMAS90(this.state.Invoice.InvoiceDate), 'Grocer:', this.state.Invoice.SalespersonNo, 'Client No:', this.state.Invoice.Customer.CustomerNo, 'Batch No:', this.state.Invoice.BatchNo]}
                                                Box1={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                Box2={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", this.textTermsCode(this.state.Invoice.Customer.TermsCode)]}
                                                DescriptionKeys={["quantityShipped", "itemCode", "upc", "abbreviatedDesc", "$", "UnitPrice", "$", "totalCost"]}
                                                HeaderDescritpion={["Quantity", "Code", "SKU", "Description", "", "    Price", "", "Amount"]}
                                                StyleDetail={["ColumnaCantidades", "ColumnaNormal", "ColumnaNormal", "ColumnaGrande", "Simbolo", "ColumnaMonetaria", "Simbolo", "ColumnaMonetariaRight"]}
                                                Detail={this.printDetail()}
                                                breakpoint={45}
                                                quantSignDolar={this.realLongDetail()}
                                                keysDecimalFormat={["UnitPrice", "totalCost"]}
                                                bottomInformation={[
                                                    NumberFormat(this.state.Invoice.TotalQuantityShipped), "", "Cash", "", "Check", "", "", "Open     Weight: " + NumberFormat(pricesFormat(this.state.Invoice.TotalWeight)), "Net Invoice: ", "$", NumberFormat(pricesFormat(this.state.Invoice.TotalCost)),
                                                    "", "", "", "", "", "", "", "", "Freight: ", "$", NumberFormat(pricesFormat(this.state.Invoice.FreightAmt)),
                                                    "", "", "", "", "", "", "", "", "Invoice Total: ", "$", NumberFormat(pricesFormat(Number(this.state.Invoice.FreightAmt) + Number(this.state.Invoice.TotalCost)))
                                                ]}
                                                bottomStyle={[
                                                    "NormalButtom", "CheckButtom", "NormalButtom", "CheckButtom", "NormalButtom", "LineButtom", "CheckButtom", "LargeButtom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom2Left", "DolarButtomLeft", "LineButtom2",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "LineButtom3Left", "DolarButtomLeft2", "LineButtom3",
                                                ]}
                                                bottomMessage={"Returned checks will incur an additional charge of $50.00. Los cheques rechazados tendran un cargo adicional de $50.00. \nNo Returns after 24 hours after delivered, No se aceptan devoluciones despues de 24 horas de entregado."}
                                                docname={this.state.Invoice.InvoiceNo}
                                            />
                                    )
                                }
                            </div>

                            <div className='col-sm-12 d-grid gap-2 pt-1'>
                                <button hidden={(this.state.Invoice.BatchNo ? false : (this.state.addTitle !== '' ? false : true))} type="button" className="btn purpleButton btn-lg" onClick={() => this.PrintAndSave("NoPrices")}>{this.state.addTitle !== 'REPRINT-' ? "Invoice Save and Print Without Prices" : "Invoice Print Without Prices"}<AiFillPrinter /></button>

                                {getValueCookie('CompanyId') !== '6' ?
                                    (
                                        MobileDisp() ?
                                            <InvoicePDF
                                                id={'InvoiceWPrint'}
                                                hidden={true}
                                                colorButton='purpleButton'
                                                titleButton='Invoice Print Without Prices'
                                                title='Invoice Print'
                                                CompanyTitle='Mayaland'
                                                InvoiceType={this.state.addTitle + "Packing List"}
                                                CompanyContactInfo={['Mayaland LLC', '7055 Amwiler Industrial Dr', 'Suite B\n Atlanta, GA 30360', '(678) 213-1226']}
                                                InvoiceHeader={['Invoice Number:', this.state.Invoice.InvoiceNo, 'Invoice Date:', DateFormatMAS90(this.state.Invoice.InvoiceDate), 'Sales Person:', this.state.Invoice.SalespersonNo, 'Customer Number:', this.state.Invoice.Customer.CustomerNo, 'Batch No:', this.state.Invoice.BatchNo]}
                                                Box1={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                Box2={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", this.textTermsCode(this.state.Invoice.Customer.TermsCode)]}
                                                DescriptionKeys={["quantityShipped", "itemCode", "SKU", "abbreviatedDesc", "", "", "", ""]}
                                                HeaderDescritpion={["Qty", "Code", "SKU", "Description", "", "", "", ""]}
                                                StyleDetail={["ColumnaCantidades", "ColumnaNormal", "ColumnaNormalCenter", "ColumnaGrande", "Simbolo", "ColumnaMonetaria", "Simbolo", "ColumnaMonetariaRight"]}
                                                Detail={this.printDetail()}
                                                breakpoint={55}
                                                quantSignDolar={this.realLongDetail()}
                                                keysDecimalFormat={["UnitPrice", "totalCost"]}
                                                bottomInformation={[
                                                    NumberFormat(this.state.Invoice.TotalQuantityShipped), "", "Cash", "", "Check", "", "", "Open     Weight: " + NumberFormat(pricesFormat(this.state.Invoice.TotalWeight)), "", "", "",
                                                    "", "", "", "", "", "", "", "", "", "", "",
                                                    "", "", "", "", "", "", "", "", "", "", ""
                                                ]}
                                                bottomStyle={[
                                                    "NormalButtom", "CheckButtom", "NormalButtom", "CheckButtom", "NormalButtom", "LineButtom", "CheckButtom", "LargeButtom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                ]}
                                                bottomMessage={"Comment:" + this.state.Invoice.Comment}
                                            />
                                            :
                                            <DownloadInvoicePDF
                                                id={'InvoiceWPrint'}
                                                hidden={true}
                                                colorButton='purpleButton'
                                                titleButton='Invoice Print Without Prices'
                                                title='Invoice Print'
                                                CompanyTitle='Mayaland'
                                                InvoiceType={this.state.addTitle + "Packing List"}
                                                CompanyContactInfo={['Mayaland LLC', '7055 Amwiler Industrial Dr', 'Suite B\n Atlanta, GA 30360', '(678) 213-1226']}
                                                InvoiceHeader={['Invoice Number:', this.state.Invoice.InvoiceNo, 'Invoice Date:', DateFormatMAS90(this.state.Invoice.InvoiceDate), 'Sales Person:', this.state.Invoice.SalespersonNo, 'Customer Number:', this.state.Invoice.Customer.CustomerNo, 'Batch No:', this.state.Invoice.BatchNo]}
                                                Box1={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                Box2={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", this.textTermsCode(this.state.Invoice.Customer.TermsCode)]}
                                                DescriptionKeys={["quantityShipped", "itemCode", "SKU", "abbreviatedDesc", "", "", "", ""]}
                                                HeaderDescritpion={["Qty", "Code", "SKU", "Description", "", "", "", ""]}
                                                StyleDetail={["ColumnaCantidades", "ColumnaNormal", "ColumnaNormalCenter", "ColumnaGrande", "Simbolo", "ColumnaMonetaria", "Simbolo", "ColumnaMonetariaRight"]}
                                                Detail={this.printDetail()}
                                                breakpoint={55}
                                                quantSignDolar={this.realLongDetail()}
                                                keysDecimalFormat={["UnitPrice", "totalCost"]}
                                                bottomInformation={[
                                                    NumberFormat(this.state.Invoice.TotalQuantityShipped), "", "Cash", "", "Check", "", "", "Open     Weight: " + NumberFormat(pricesFormat(this.state.Invoice.TotalWeight)), "", "", "",
                                                    "", "", "", "", "", "", "", "", "", "", "",
                                                    "", "", "", "", "", "", "", "", "", "", ""
                                                ]}
                                                bottomStyle={[
                                                    "NormalButtom", "CheckButtom", "NormalButtom", "CheckButtom", "NormalButtom", "LineButtom", "CheckButtom", "LargeButtom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                ]}
                                                bottomMessage={"Comment:" + this.state.Invoice.Comment}
                                                docname={"Packing List " + this.state.Invoice.InvoiceNo}
                                            />
                                    )
                                    :
                                    (
                                        MobileDisp() ?
                                            <InvoicePDF2
                                                id={'InvoiceWPrint'}
                                                hidden={true}
                                                colorButton='purpleButton'
                                                titleButton='Invoice Print Without Prices'
                                                title='Invoice Print'
                                                companyLogo={getValueCookie('DocumentsLogo')}
                                                InvoiceType={this.state.addTitle + "Packing List"}
                                                CompanyContactInfo={['Altitude LLC', '7055 Amwiler Industrial Dr', 'Suite B-C', 'Atlanta, GA 30360', '(770) 871-5807']}
                                                InvoiceHeader={['Invoice Number:', this.state.Invoice.InvoiceNo, 'Date:', DateFormatMAS90(this.state.Invoice.InvoiceDate), 'Grocer:', this.state.Invoice.SalespersonNo, 'Client No:', this.state.Invoice.Customer.CustomerNo, 'Batch No:', this.state.Invoice.BatchNo]}
                                                Box1={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                Box2={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", "Pay in Advance"]}
                                                DescriptionKeys={["quantityShipped", "itemCode", "upc", "abbreviatedDesc", "", "", "", ""]}
                                                HeaderDescritpion={["Quantity", "Code", "SKU", "Description", "", "", "", ""]}
                                                StyleDetail={["ColumnaCantidades", "ColumnaNormal", "ColumnaNormal", "ColumnaGrande", "Simbolo", "ColumnaMonetaria", "Simbolo", "ColumnaMonetariaRight"]}
                                                Detail={this.printDetail()}
                                                breakpoint={45}
                                                quantSignDolar={this.realLongDetail()}
                                                keysDecimalFormat={["UnitPrice", "totalCost"]}
                                                bottomInformation={[
                                                    NumberFormat(this.state.Invoice.TotalQuantityShipped), "", "Cash", "", "Check", "", "", "Open     Weight: " + NumberFormat(pricesFormat(this.state.Invoice.TotalWeight)), "", "", "",
                                                    "", "", "", "", "", "", "", "", "", "", "",
                                                    "", "", "", "", "", "", "", "", "", "", ""
                                                ]}
                                                bottomStyle={[
                                                    "NormalButtom", "CheckButtom", "NormalButtom", "CheckButtom", "NormalButtom", "LineButtom", "CheckButtom", "LargeButtom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                ]}
                                                bottomMessage={""}

                                            />
                                            :
                                            <DownloadInvoicePDF2
                                                id={'InvoiceWPrint'}
                                                hidden={true}
                                                colorButton='purpleButton'
                                                titleButton='Invoice Print Without Prices'
                                                title='Invoice Print'
                                                companyLogo={getValueCookie('DocumentsLogo')}
                                                InvoiceType={this.state.addTitle + "Packing List"}
                                                CompanyContactInfo={['Altitude LLC', '7055 Amwiler Industrial Dr', 'Suite B-C', 'Atlanta, GA 30360', '(770) 871-5807']}
                                                InvoiceHeader={['Invoice Number:', this.state.Invoice.InvoiceNo, 'Date:', DateFormatMAS90(this.state.Invoice.InvoiceDate), 'Grocer:', this.state.Invoice.SalespersonNo, 'Client No:', this.state.Invoice.Customer.CustomerNo, 'Batch No:', this.state.Invoice.BatchNo]}
                                                Box1={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                Box2={[this.state.Invoice.Customer.CustomerName, this.state.Invoice.Customer.AddressLine1, this.state.Invoice.Customer.City + ", " + this.state.Invoice.Customer.State + " " + this.state.Invoice.Customer.ZipCode]}
                                                HeaderOptions={["Customer P.O.", "Ship VIA", "F.O.B.", "Terms", " ", " ", " ", "Pay in Advance"]}
                                                DescriptionKeys={["quantityShipped", "itemCode", "upc", "abbreviatedDesc", "", "", "", ""]}
                                                HeaderDescritpion={["Quantity", "Code", "SKU", "Description", "", "", "", ""]}
                                                StyleDetail={["ColumnaCantidades", "ColumnaNormal", "ColumnaNormal", "ColumnaGrande", "Simbolo", "ColumnaMonetaria", "Simbolo", "ColumnaMonetariaRight"]}
                                                Detail={this.printDetail()}
                                                breakpoint={45}
                                                quantSignDolar={this.realLongDetail()}
                                                keysDecimalFormat={["UnitPrice", "totalCost"]}
                                                bottomInformation={[
                                                    NumberFormat(this.state.Invoice.TotalQuantityShipped), "", "Cash", "", "Check", "", "", "Open     Weight: " + NumberFormat(pricesFormat(this.state.Invoice.TotalWeight)), "", "", "",
                                                    "", "", "", "", "", "", "", "", "", "", "",
                                                    "", "", "", "", "", "", "", "", "", "", ""
                                                ]}
                                                bottomStyle={[
                                                    "NormalButtom", "CheckButtom", "NormalButtom", "CheckButtom", "NormalButtom", "LineButtom", "CheckButtom", "LargeButtom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                    "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "ClearBottom", "NormalButtom", "NormalButtom", "NormalButtom",
                                                ]}
                                                bottomMessage={""}
                                                docname={"Packing List " + this.state.Invoice.InvoiceNo}
                                            />
                                    )
                                }

                            </div>
                        </div>
                    </div>
                    <div className='col-sm-1'></div>
                </div>
                <SmallModal backdrop={'static'} centered={true} size={'md'} title={'New Batch Number'} show={this.state.showModal1} close={(modal = "showModal1") => this.handleModalClose(modal)}>
                    <div className='row'>
                        <div className="col-sm-12 pt-1 input-group input-group-lg flex-nowrap">
                            <input className="form-control form-control-lg text-center" defaultValue={this.state.Invoice.BatchNo} id='batchNoInputInvoice' type="text" placeholder='Batch Number' onChange={(e) => this.onTarget(e, '')} />
                            <span className="input-group-text">
                                <div className='d-grid gap-2'>
                                    <button type="button" className="btn yellowButton btn-lg" onClick={() => this.getNewCorrelativeBatchNumber()}>Generate Batch</button>
                                </div>
                            </span>
                        </div>

                    </div>
                    <div className='row pt-3'>
                        <div className='col-sm-6 pb-2 d-grid gap-2'>
                            <button type="button" disabled={this.state.temporalBatchNo === ''} className="btn greenButton btn-lg" onClick={() => this.ConfirmBatchNumber()}>Accept</button>
                        </div>
                        <div className='col-sm-6 pb-2 d-grid gap-2'>
                            <button disabled={this.state.Invoice.BatchNo === ''} type="button" className="btn redButton btn-lg" onClick={() => this.cancelWithBatchNo()}>Cancel</button>
                        </div>
                    </div>
                </SmallModal>
                <SmallModal centered={false} size={'xl'} title={'Balance: ' + this.state.Invoice.Customer.CustomerName} show={this.state.showModal2} close={(modal = "showModal2") => this.handleModalClose(modal)}>
                    <div className='row pt-2'>
                        <div className='col-sm-12 pb-2'>
                            <h3>Terms Code: {this.state.Balance.TermsCode}</h3>
                        </div>
                        <div className='col-sm-12 tableFixHead'>
                            <table className='table'>
                                <thead className='thead'>
                                    <tr className='bg-dark text-white'>
                                        <th className='text-center bg-dark'>Invoice No</th>
                                        <th className='text-center bg-dark'>Date</th>
                                        <th className='text-center bg-dark'>Due Date</th>
                                        <th className='text-center bg-dark'>Payment Delay Days</th>
                                        <th className='text-center bg-dark'>Customer Name</th>
                                        <th className='text-center bg-dark'>Sales Person</th>
                                        <th></th>
                                        <th className='text-center bg-dark'>Balance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.Balance.HeaderBalance.map((element, e) => (
                                            <tr className={element.InvoiceNo === this.state.Balance.SelectedInvoice ? 'bg-warning' : ''} onClick={() => this.getDetailBalance(element)} key={e}>
                                                <td className='text-start'>{element.InvoiceNo}</td>
                                                <td className='text-center'>{element.InvoiceDate}</td>
                                                <td className='text-center'>{element.InvoiceDueDate}</td>
                                                <td className='text-center'>{element.PaymentDelayDays}</td>
                                                <td className='text-center'>{this.state.Invoice.Customer.CustomerName}</td>
                                                <td className='text-center'>{element.SalespersonNo}</td>
                                                <td>$</td>
                                                <td className='text-end'>{pricesFormat(element.Balance)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot className='tfoot'>
                                    <tr className='bg-secondary text-center text-white'>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>TOTAL:</td>
                                        <td>$</td>
                                        <td className='text-end'>{pricesFormat(this.state.Balance.TotalBalance)}</td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div className='col-sm-12'>
                            <h3 className='text-start'>Detail: {this.state.Balance.SelectedInvoice}</h3>
                        </div>
                        <div className='col-sm-12 tableFixHead'>
                            <table className='table'>
                                <thead className='thead'>
                                    <tr className='bg-dark text-white'>
                                        <th className='text-center bg-dark'>Invoice No</th>
                                        <th className='text-center bg-dark'>Invoice Type</th>
                                        <th className='text-center bg-dark'>Transaction Type</th>
                                        <th className='text-center bg-dark'>Transaction Date</th>
                                        <th></th>
                                        <th className='text-center bg-dark'>Transaction Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.Balance.Detail.map((element, e) => (
                                            <tr key={e}>
                                                <td className='text-start'>{element.InvoiceNo}</td>
                                                <td className='text-center'>{element.InvoiceType}</td>
                                                <td className='text-center'>{element.TransactionType}</td>
                                                <td className='text-center'>{DateFormatMAS90(element.TransactionDate)}</td>
                                                <td className='text-end'>$</td>
                                                <td className='text-end'>{pricesFormat(element.TransactionAmt)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                <tfoot className='tfoot'>

                                </tfoot>
                            </table>
                        </div>



                        <div className='col-sm-6 pt-2'>
                            <OrderPDF id={"CurrentBalancePrintSalesOrder"} colorButton="orangeButton" title="Sales Order Print"
                                companyLogo={getValueCookie('DocumentsLogo')}
                                OrderTitle="Current Balance"
                                contactInfo={this.state.companyPrintHeader}
                                OrderInfo1={[
                                    "Customer No:", this.state.Invoice.Customer.CustomerNo, "Print By:", getValueCookie('userName'),
                                    "Customer Name:", this.state.Invoice.Customer.CustomerName, "Printing Date:", FormatQueryReturnDateForReport(getActualDateUTC()),
                                    "Phone:", this.state.Invoice.Customer.TelephoneNo, "Terms Code:", this.state.Balance.TermsCode,
                                    "Address:", this.state.Invoice.Customer.AddressLine1, "", "",
                                    "City", this.state.Invoice.Customer.City, "", ""
                                ]}
                                headerTable={["Invoice No", "Date", "Due\nDate", "Payment Delay Days", "Customer\nName", "Sales\nPerson", "", "Balance"]}
                                bodyTable={this.state.Balance.HeaderBalance}
                                headerBodyTable={["InvoiceNo", "InvoiceDate", "InvoiceDueDate", "PaymentDelayDays", "CustomerName", "SalespersonNo", "dolar", "Balance"]}
                                bottomInfo={["", "", "", "", "", "TOTAL", "$", pricesFormat(this.state.Balance.TotalBalance)]}
                                styleColumns={["ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaTextoNormal", "ColumnaCantidades", "ColumnaTextoLargo2", "ColumnaCantidades", "Dolar", "ColumnaPrecios"]}
                                hidden={false}
                                comment={""}
                                keysDecimalFormat={["Balance"]}
                                breakpoint={28}
                                disabled={false}
                                titleButton='Print Balance'
                            />
                        </div>
                        <div className='col-sm-6 pt-2'>
                            <div className="d-grid gap-2">
                                <button type="button" onClick={() => this.handleModalClose("showModal2")} className="btn redButton btn-lg">Close</button>
                            </div>
                        </div>
                    </div>

                </SmallModal>
            </div>
        )
    }
}