import server from "../services/Connection/backEndLink";
import axios from "axios";
import * as XLSX from 'xlsx/dist/xlsx.full.min.js';
const baseURLAuth = server.url;

const filteredKeys = ['SalesOrderNo', 'ItemCode', 'ItemCodeDesc', 'QuantityOrdered', 'UnitPrice']

const apiUrl = `${baseURLAuth}/sales/get/detail`;
const apiJsonUrl = `${baseURLAuth}/sales/get/header/ByDate`;
const postData = {
  data: {
    idCompany: 3, 
    idcompany: 3, 
    SalespersonNo: "*"
  }
}
let allSalesOrders = [];

async function getSalesOrders() {
  try{
    const response = await axios.post(apiJsonUrl, postData);
    console.log('Getting the Order Sales')

    if (response.data.status.code === 1) {
      const responseData = response.data.data
      console.log('Saving in data.json')
      allSalesOrders = responseData;
      console.log("Get the month sales orders")
    }

  }catch(error){
    console.log(`Error in the api request: ${error}`)
  }
}

async function fetchAndSaveToExcel(jsonData, apiUrl, keysToExtract) {
  console.log(jsonData)
  const ids = jsonData.map(item => item.SalesOrderNo); // Get the IDs
  const responses = [];

  // Make a request for every ID
  for (const id of ids) {
    
      try {
        const response = await axios.post(apiUrl, { data: { OrderNo: id, idCompany: 3 } });
        console.log(`Processing ID ${id}:`);

        // If is it an array it iterates itself
        const dataResponse = response.data.data;
        let setTable = dataResponse.Tabla2.length > 0 ? dataResponse.Tabla2 : dataResponse.Tabla1
        const responseData = Array.isArray(setTable) ? setTable : [setTable];

        // Extract the columns
        responseData.forEach(item => {
            const filteredData = {};
            keysToExtract.forEach(key => {
                filteredData[key] = item[key];
            });
            responses.push({ ...filteredData }); // Extract the information
        });
      } catch (error) {
        console.log("Error:", error)
      }
  }

  // Generate a excel document
  const wb = XLSX.utils.book_new(); 

  // Save the filtered responses in the first tab
  const inputSheet = XLSX.utils.json_to_sheet(jsonData); 
  XLSX.utils.book_append_sheet(wb, inputSheet, "Input Data");

  // Save the filtered responses in the second tab
  const responseSheet = XLSX.utils.json_to_sheet(responses);
  XLSX.utils.book_append_sheet(wb, responseSheet, "Filtered API Responses");
  
  cleanReport(wb)
  // Save the excel file
  // XLSX.writeFile(wb, "filtered_api_responses.xlsx");
}

async function cleanReport(workbook){
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    // Remove unused columns
    const delColumns = [2,6,7,8,9,11,12,13,15,16,17,18,20,21,22,23]
    delColumns.sort((a, b) => b - a);
    delColumns.forEach(col => {
      worksheet.spliceColumns(col, 1); // remove the column 'col'
    });
    
    const textTitles = ['Sales Order No','Order Date','Customer No','Bill to Name','Sales Person','Total Amt($)', 'Route']

    textTitles.forEach((title, index) => {
      const colLetter = String.fromCharCode(65 + index); // Convert the index to character
      const cell = worksheet.getCell(`${colLetter}1`);
      cell.value = title;
      cell.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF000000' }, // Fondo negro
      };
    })
    
    const worksheet2 = workbook.Sheets[workbook.SheetNames[1]];
    const textTitles2 = ['Sales Order No','Item Code','Description','Quantity Ordered','Unit Price ($)','Total Amt ($)']

    // Create Total column and calculate result
    const totalColumn = worksheet2.columnCount + 1;
    worksheet2.eachRow({ includeEmpty: true }, (row, rowNumber) => {
      if (rowNumber > 1) {
        const quantity = row.getCell(4).value;
        const unitPrice = row.getCell(5).value;

        // Calculate the total
        if (typeof quantity === 'number' && typeof unitPrice === 'number') {
          const total = quantity * unitPrice;
          row.getCell(totalColumn).value = total; // Set the total amount
        }
      }
    })

    // Update styles
    textTitles2.forEach((title, index) => {
      const colLetter = String.fromCharCode(65 + index); // Convert the index to character
      const cell2 = worksheet2.getCell(`${colLetter}1`);
      cell2.value = title;
      cell2.font = { bold: true, color: { argb: 'FFFFFFFF' } };
      cell2.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FF000000' },
      };
    })

    for (let i = 1; i <= worksheet2.columnCount; i++) {
      worksheet2.getColumn(i).width = 30; // Adjust the width of all columns
    }
    workbook.xlsx.writeFile('output.xlsx');
}

async function downloadExcel() {
    const a = document.createElement('a');;
    a.download = 'output.xlsx';

    // Simulate click to start the download
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

}

export async function generateSOReport() {
  await getSalesOrders()
  await fetchAndSaveToExcel(allSalesOrders, apiUrl, filteredKeys);
  await downloadExcel()
}